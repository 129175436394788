@import "@fontsource/inter/variable.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
body > #root {
  height: 100%;
  min-width: 518px;
}
